import React from 'react';
import LocalizedStrings from 'react-localization';

import RoomsComponent from './RoomsComponent';

import STRINGS_IT from '../../assets/data/rooms.json';
import STRINGS_EN from '../../assets/data/en/rooms.json';
import tree from '../../assets/img/tree.png';
import nut from '../../assets/img/nut.png';
import wind from '../../assets/img/wind.png';
import flower from '../../assets/img/flower.png';
import roomSelva from '../../assets/img/cover_selva.png';
import roomOcra from '../../assets/img/cover_ocra.png';
import roomCobalto from '../../assets/img/cover_cobalto.png';
import roomMagenta from '../../assets/img/cover_magenta.png';

const strings = new LocalizedStrings({
    en: STRINGS_EN,
    it: STRINGS_IT,
});

class RoomsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRoom: 0
        }
    }

    render() {
        if(localStorage.getItem('Function')) strings.setLanguage(JSON.parse(localStorage.getItem('Function')).language);;

        return (
            <RoomsComponent
                selectedRoom={this.state.selectedRoom}
                onRoomChange={(event, room) => this.setState({ selectedRoom: room })}
                strings={strings}
                pictures={{
                    covers: [
                        roomSelva,
                        roomOcra,
                        roomCobalto,
                        roomMagenta
                    ],
                    tree: tree,
                    nut: nut,
                    wind: wind,
                    flower: flower,
                }}/>
        )
    }
}

export default RoomsContainer;
