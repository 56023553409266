import React from 'react';
import BackgroundSlider from '../common/lib_slider/index';
// import BackgroundSlider from 'react-background-slider';

import { Parallax } from 'react-scroll-parallax';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './Who.scss';
import image1 from '../../assets/img/who/001.jpg';
import image2 from '../../assets/img/who/002.png';
import image3 from '../../assets/img/who/003.png';

import Quote from '../common/quote/Quote';
import TextBox from '../common/textbox/TextBox';

const WhoComponent = ({ strings, pictures }) => {
    const mainTextBox = [
        {
            body: strings.main[0],
            centered: true
        },
        {
            src: pictures.pillow
        },
        {
            body: strings.main[1],
            centered: true
        }
    ];

    const antonellaTextBox = [
        {
            body: strings.antonella[0],
            centered: true,
            title: true
        },
        {
            src: pictures.teapot
        },
        {
            body: strings.antonella[1],
            centered: true
        },
        {
            src: pictures.cup
        },
        {
            body: strings.antonella[2],
            centered: true
        },
        {
            src: pictures.squeezer
        },
        {
            body: strings.antonella[3],
            centered: true
        },
    ];

    const emilioTextBox = [
        {
            body: strings.emilio[0],
            centered: true,
            title: true
        },
        {
            src: pictures.scissors
        },
        {
            body: strings.emilio[1],
            centered: true
        },
        {
            src: pictures.plant
        },
        {
            body: strings.emilio[2],
            centered: true
        },
        {
            src: pictures.boots
        },
        {
            body: strings.emilio[3],
            centered: true
        },
    ];

    return (
        <div className="Who">
            <div className="wrapper wrapper--padded">
                <Grid
                    container
                    spacing={32}
                    alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Parallax
                            offsetYMax={60}
                            offsetYMin={-60}
                            slowerScrollRate
                            tag="figure"
                            >
                            <img src={image1}/>
                        </Parallax>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Quote
                            title={strings.cover.quote[0]}
                            subtitle={strings.cover.quote[1]}
                            />
                    </Grid>


                    <Hidden smDown><Grid item xs={3}/></Hidden>
                    <Grid item xs={12} md={6}>
                        <div className="padder" style={{width:"100%", height:"128px"}} />
                        <TextBox lines={mainTextBox} />
                    </Grid>
                    <Hidden smDown><Grid item xs={3}/></Hidden>


                    <Grid item xs={12} md={6}>
                        <TextBox lines={antonellaTextBox}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Parallax
                            offsetYMax={20}
                            offsetYMin={-20}
                            slowerScrollRate
                            tag="figure"
                            >
                            <img src={image2}/>
                        </Parallax>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Parallax
                            offsetYMax={60}
                            offsetYMin={-60}
                            slowerScrollRate
                            tag="figure"
                            >
                            <img src={image3}/>
                        </Parallax>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextBox lines={emilioTextBox}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default WhoComponent;
