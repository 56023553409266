import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import { MuiThemeProvider } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Theme from './Theme';
import LandingLayout from "./app/common/layout/LandingLayout";
import Home from "./app/home/HomeContainer";
import Who from "./app/who/WhoContainer";
import Habitat from "./app/habitat/HabitatContainer";
import Rooms from "./app/rooms/RoomsContainer";
import Breakfast from "./app/breakfast/BreakfastContainer";
import Contacts from "./app/contacts/ContactsContainer";
import Bike from "./app/bike/BikeContainer";

const App = ({ width }) => (
    <ParallaxProvider>
        <MuiThemeProvider theme={Theme[width]}>
            <Router>
                <Switch>
                    <LandingLayout exact path="/" component={Home} />
                    <LandingLayout path="/who" component={Who} />
                    <LandingLayout path="/habitat" component={Habitat} />
                    <LandingLayout path="/rooms" component={Rooms} />
                    <LandingLayout path="/breakfast" component={Breakfast} />
                    <LandingLayout path="/contacts" component={Contacts} />
                    <LandingLayout path="/bike" component={Bike} />
                </Switch>
            </Router>
        </MuiThemeProvider>
    </ParallaxProvider>
);

export default withWidth()(App);
