import React from 'react';

import ContactsComponent from './ContactsComponent';

import STRINGS from '../../assets/data/contacts.json';

class HabitatContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ContactsComponent
                strings={STRINGS}
                />
        )
    }
}

export default HabitatContainer;
