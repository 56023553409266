import { createMuiTheme } from '@material-ui/core/styles';

const Theme = {
  palette: {
      primary: {
          main: "#41C8A4",
          contrastText: "#efefef"
      },
      secondary: {
          main: "#efefef",
          contrastText: "#333333"
      }
  },
  typography: {
      useNextVariants: true,
      fontFamily: "Cormorant",
      h1: {
          color: "#323648",
          fontFamily: "Montserrat",
          fontSize: "1.6rem",
          lineHeight: "2rem",
          fontWeight: "700",
      },
      h2: {
          color: "#000000",
          fontSize: "2rem",
          lineHeight: "2.5rem",
          marginBottom: ".4rem",
          fontWeight: "700",
      },
      h4: {
          color: "#f2f2f2",
          fontSize: "2rem",
          lineHeight: "2.4rem",
          marginBottom: "12px",
      },
      h5: {
          color: "#f2f2f2",
          fontFamily: "Montserrat",
          fontSize: "1.1rem",
          fontWeight: "700",
      },
      h6: {
          color: "#ffffff",
          fontSize: "1.2rem",
          fontWeight: "700",
      },
      body2: {
          fontSize: "1.25rem",
          fontWeight: "600",
          lineHeight: "2rem",
          color: "#5b5e6d",
      },
      subtitle2: {
          fontFamily: "Montserrat",
          fontSize: ".9rem",
          lineHeight: "1.8rem",
          color: "#f2f2f2",
      },
      button: {
          fontSize: "1.1rem",
          fontWeight: "600",
          color: "#ffffff",
          textTransform: "none"
      }
  },
};

const SM = Object.assign({}, Theme, {
    typography: {
        ...Theme.typography,
    }
});

const MD = Object.assign({}, Theme, {
    typography: {
        ...Theme.typography,
        h1: {
            color: "#323648",
            fontFamily: "Montserrat",
            fontSize: "1.6rem",
            lineHeight: "2rem",
            fontWeight: "700",
        },
        h2: {
            color: "#000000",
            fontSize: "4rem",
            lineHeight: "6rem",
            marginBottom: ".4rem",
            fontWeight: "700",
        },
        h4: {
            color: "#f2f2f2",
            fontSize: "2rem",
            lineHeight: "2.4rem",
            marginBottom: "12px",
        },
        h5: {
            color: "#f2f2f2",
            fontFamily: "Montserrat",
            fontSize: "1.1rem",
            fontWeight: "700",
        },
        h6: {
            color: "#ffffff",
            fontSize: "1.2rem",
            fontWeight: "700",
        },
        body2: {
            fontSize: "1.25rem",
            fontWeight: "600",
            lineHeight: "2rem",
            color: "#5b5e6d",
        },
        subtitle2: {
            fontFamily: "Montserrat",
            fontSize: ".9rem",
            lineHeight: "1.8rem",
            color: "#f2f2f2",
        },
        button: {
            fontSize: "1.1rem",
            fontWeight: "600",
            color: "#ffffff",
            textTransform: "none"
        }
    }
});

const LG = Object.assign({}, MD, {
    typography: {
        ...MD.typography,
    }
});

export default {
    xs: createMuiTheme(Theme),
    sm: createMuiTheme(SM),
    md: createMuiTheme(MD),
    lg: createMuiTheme(LG),
    xl: createMuiTheme(LG)
};
