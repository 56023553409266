import React from 'react';
import BackgroundSlider from '../common/lib_slider/index';
import Markdown from 'react-markdown';
// import BackgroundSlider from 'react-background-slider';

import { ParallaxBanner } from 'react-scroll-parallax';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ThumbUp from '@material-ui/icons/ThumbUp';

import './Rooms.scss';
import image1 from '../../assets/img/rooms/001.jpg';
import image2 from '../../assets/img/rooms/002.jpg';
import image3 from '../../assets/img/rooms/003.jpg';
import image4 from '../../assets/img/rooms/004.jpg';
import image5 from '../../assets/img/rooms/005.jpg';
import image6 from '../../assets/img/rooms/006.jpg';
import image7 from '../../assets/img/rooms/007.jpg';
import image8 from '../../assets/img/rooms/008.jpg';
import image9 from '../../assets/img/rooms/009.jpg';

import Quote from '../common/quote/Quote'
import TextBox from '../common/textbox/TextBox'

const Title = ({level, children}) => <Typography variant="h3">{children}</Typography>
const Text = ({children}) => <Typography variant="body2">{children}</Typography>
const Bold = ({children}) => <span><strong>{children[0].props.value[0]}</strong>{children[0].props.value.substring(1)}</span>

const RoomsComponent = ({ selectedRoom, onRoomChange, strings, pictures }) => {
    return (
        <div className="Rooms">
            <BackgroundSlider
                images={[
                    image1,
                    image2,
                    image3,
                    image4,
                    image5,
                    image6,
                    image7,
                    image8,
                    image9,
                ]}
                duration={2}/>
            <Grid
                container
                alignItems="center"
                style={{height: "640px"}}>
                <Grid item xs={12} md={6}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Quote
                        title={strings.cover.quote[0]}
                        subtitle={strings.cover.quote[1]}
                        negative
                        />
                </Grid>
            </Grid>

            <div className="wrapper Rooms__text">
                <Grid container>
                    <AppBar position="sticky" color="default" className="Rooms__tabbar">
                        <Tabs
                            value={selectedRoom}
                            onChange={onRoomChange}
                            indicatorColor="transparent"
                            textColor="primary"
                            fullWidth
                            >
                            <Tab label="Selva" icon={<img src={pictures.tree} style={{ width: "64px" }} />} />
                            <Tab label="Ocra" icon={<img src={pictures.nut} style={{ width: "64px" }} />} />
                            <Tab label="Cobalto" icon={<img src={pictures.wind} style={{ width: "64px" }} />} />
                            <Tab label="Magenta" icon={<img src={pictures.flower} style={{ width: "64px" }} />} />
                        </Tabs>


                    </AppBar>
                    <Grid item xs={2} />
                    <Grid item xs={8} style={{textAlign: "center"}}>
                        <img src={pictures.covers[selectedRoom]} style={{ width: "100%" }}/>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={4} />
                    <Grid item xs={4} style={{textAlign: "center"}}>
                        <Markdown source={strings.rooms[selectedRoom].description} renderers={{paragraph: Text, heading: Title, strong: Bold}}/>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={2} />
                    <Grid item xs={8} style={{textAlign: "center", marginTop: "64px", marginBottom: "128px"}}>
                        <Markdown className="Rooms__quote" source={strings.rooms[selectedRoom].quote} renderers={{paragraph: Text, heading: Title, strong: Bold}}/>
                        <Typography variant="body2" style={{marginTop: "16px"}}><small>{strings.rooms[selectedRoom].author}</small></Typography>
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
            </div>
        </div>
    )
}

export default RoomsComponent;
