import React from 'react';
import Markdown from 'react-markdown';

import Typography from '@material-ui/core/Typography';

import './TextBox.scss';

const Title = ({level, children}) => <Typography variant="h3">{children}</Typography>
const Text = ({children}) => <Typography variant="body2">{children}</Typography>
const Bold = ({children}) => <span><strong>{children[0].props.value[0]}</strong>{children[0].props.value.substring(1)}</span>

const TextBox = ({ lines }) => (
    <div className="textbox">
        {lines.map((line => (
            <div className={`textbox__line ${line.src ? "textbox__line--image textbox__line__image--padding-" + line.padding : line.centered ? "textbox__line--centered" : ""}`}>
                {line.src ? <img src={line.src}/> : <Markdown source={line.body} renderers={{paragraph: Text, heading: Title, strong: Bold}}/>}
            </div>
        )))}
    </div>
)

export default TextBox;
