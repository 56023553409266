import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import CONTACTS from '../../../assets/data/contacts.json';
import airbnbLogo from '../../../assets/img/airbnb.png';
import partner001 from '../../../assets/img/partner001.jpg';
import partner002 from '../../../assets/img/partner002.png';
import './Footer.scss';

const partners = [
    {
        logo: partner002,
        href: "http://biketourrimini.it/"
    },
    {
        logo: partner001,
        href: "http://www.santarcangelodiromagna.info/"
    },
]

const links = [
    {
        logo: "google",
        href: "https://www.google.it/maps/place/la+combriccola+B%26b/@44.0350395,12.4855756,17z/data=!3m1!4b1!4m2!3m1!1s0x132cc1b599ca934d:0xe74d55f3b89cff6e"
    },
    {
        logo: "facebook-f",
        href: "https://www.facebook.com/La-Combriccola-536466359759919/?fref=ts"
    },
    {
        logo: "tripadvisor",
        href: "https://www.tripadvisor.it/Hotel_Review-g1315192-d5928611-Reviews-La_Combriccola-Santarcangelo_di_Romagna_Province_of_Rimini_Emilia_Romagna.html"
    },
]

const Footer = () => (
    <div className="Footer">
        <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
                <div className="Footer__contacts">
                    <Typography variant="body2">{CONTACTS.email}</Typography>
                    <Typography variant="body2">
                        <div>{CONTACTS.address};</div>
                        <div>{CONTACTS.cap} {CONTACTS.city}, {CONTACTS.province}</div>
                    </Typography>
                    <Typography variant="body2">
                        <span style={{marginRight: 16}}>Tel. {CONTACTS.phone}</span>
                        <span>Antonella. {CONTACTS.antonella}</span>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="Footer__partners">
                    {partners.map((partner) => <a href={partner.href} target="_blank"><img src={partner.logo} /></a>)}
                </div>
                <div className="Footer__links">
                    {links.map((link) => <a href={link.href} target="_blank"><Icon className={`fab fa-${link.logo}`} /></a>)}
                    <a href={"https://www.airbnb.it/rooms/1871290"} target="_blank"><img src={airbnbLogo} /></a>
                </div>
            </Grid>
        </Grid>
    </div>
)

export default Footer;
