import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';
import BackgroundSlider from '../common/lib_slider/index';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import logo from '../../assets/img/logo.png';
import image1 from '../../assets/img/home/001.jpg';
import image2 from '../../assets/img/home/002.jpg';
import image3 from '../../assets/img/home/003.jpg';
import image4 from '../../assets/img/home/004.jpg';
import image5 from '../../assets/img/home/005.jpg';
import image6 from '../../assets/img/home/006.jpg';
import image7 from '../../assets/img/home/007.jpg';
import './Home.scss';

const Title = ({level, children}) => <Typography variant="h4">{children}</Typography>
const Text = ({children}) => <Typography variant="body2">{children}</Typography>

const HomeComponent = ({ strings }) => {

    return (
        <div className="Home">
            <BackgroundSlider
                images={[
                    image1,
                    image2,
                    image3,
                    image4,
                    image5,
                    image6,
                    image7
                ]}
                duration={2}/>

            <div className="wrapper">
                <div className="Home__partner">
                    <Markdown source={strings.message} renderers={{paragraph: Text, heading: Title}}/>
                    <Button className='Home__partner__cta' variant='outlined' color='secondary' component={Link} to='/bike'>{strings.offers}</Button>
                    <Typography variant="h6">{strings.partner}: <a href="http://biketourrimini.it/" target="_blank">Bike Tour Rimini</a></Typography>
                </div>
                <Hidden smDown>
                    <div className="Home__logo">
                        <img src={logo} />
                        <Typography variant="h6">{strings.abstract}</Typography>
                    </div>
                </Hidden>
            </div>
        </div>
    )
}

export default HomeComponent;
