import React from 'react';
import LocalizedStrings from 'react-localization';

import STRINGS_IT from '../../assets/data/bike.json';
import STRINGS_EN from '../../assets/data/en/bike.json';
import bike1 from '../../assets/img/bike/bike01.jpg';
import bike2 from '../../assets/img/bike/bike02.jpg';
import doc from '../../assets/docs/escursioni.pdf';
import BikeComponent from './BikeComponent';

const strings = new LocalizedStrings({
    en: STRINGS_EN,
    it: STRINGS_IT,
});

class BikeContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(localStorage.getItem('Function')) strings.setLanguage(JSON.parse(localStorage.getItem('Function')).language);;

        return (
            <BikeComponent
                strings={strings}
                pictures={{
                    bike1,
                    bike2,
                }}
                document={doc}/>
        )
    }
}

export default BikeContainer;
