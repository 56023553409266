import React from 'react';
import LocalizedStrings from 'react-localization';
// import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import persist from 'react-localstorage-hoc';

import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
// import Fade from '@material-ui/core/Fade';
import MenuIcon from '@material-ui/icons/Menu';

import logo from '../../../assets/img/logo.png';
import STRINGS_IT from '../../../assets/data/navbar.json';
import STRINGS_EN from '../../../assets/data/en/navbar.json';
import "./LandingLayout.scss";

const LINKS = [
    {
        label: "Home",
        href: "/"
    },
    {
        label: "Chi siamo",
        href: "/who"
    },
    {
        label: "L'ambiente",
        href: "/habitat"
    },
    {
        label: "Le camere",
        href: "/rooms"
    },
    {
        label: "La colazione",
        href: "/breakfast"
    },
    {
        label: "Contatti",
        href: "/contacts"
    }
]

const strings = new LocalizedStrings({
    en: STRINGS_EN,
    it: STRINGS_IT,
});

const MobileLinks = ({lang, onClick, onLanguageToggle, ...props}) => (
    <Modal {...props}>
        <div className="MobileMenu">
            {strings.links.map((link) => <div key={`molink-${link.link}`} className="MobileMenu__link"><Link to={link.link} onClick={onClick}><Typography variant="button">{link.label}</Typography></Link></div>)}
            <div>
                <Button variant='outlined' onClick={onLanguageToggle}>{lang === 'en' ? 'italiano' : 'english'}</Button>
            </div>
        </div>
    </Modal>
)

const setLang = () => {
    let currLang = localStorage.getItem('lang');
    localStorage.setItem('lang', currLang === null || currLang === 'en' ? 'it' : 'en');
}

const Navbar = ({ lang, onOverlayToggle, onLanguageToggle }) => {
    if(localStorage.getItem('Function')) strings.setLanguage(JSON.parse(localStorage.getItem('Function')).language);;

    return (
        <nav className={`Navbar ${window.location.pathname === "/who" || window.location.pathname === "/bike" ? "Navbar--dark" : ""}`}>
            <div className="Navbar__wrapper">
                <Hidden smUp>
                    <img
                        style={{
                            display: 'block',
                            height: 48,
                            marginTop: 8,
                            marginRight: 'auto',
                            filter: "brightness(0)",
                        }}
                        src={logo} />
                </Hidden>
                <div className="Navbar__center">
                    <ul>
                        {strings.links.map((link) => <li key={`link-${link.link}`}><Link to={link.link}><Typography variant="button">{link.label}</Typography></Link></li>)}
                        <li>
                            <Button color={window.location.pathname === "/who" || window.location.pathname === "/bike" ? '' : 'secondary'} variant='outlined' onClick={onLanguageToggle}>{lang === 'en' ? 'italiano' : 'english'}</Button>
                        </li>
                    </ul>
                </div>
                <div className="Navbar__burger">
                    <IconButton onClick={onOverlayToggle}>
                        <MenuIcon />
                    </IconButton>
                </div>
            </div>
        </nav>
    )
}

class LandingLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleLinks: false,
            language: 'it',
        }
    }

    componentWillMount() {
        console.log('hi');

        !localStorage.getItem('Function') && this.setState({
            visibleLinks: false,
            language: 'it',
        });
    }

    handleOverlayToggle = () => {
        this.setState(prevState => ({ visibleLinks: !prevState.visibleLinks }));
    }

    handleToggleLanguage = () => {
        this.setState(prevState => ({
            language: prevState.language === null || prevState.language === 'en' ? 'it' : 'en'
        }));
    }

    render() {
        return (
            <div className="Landing-layout">
                <Navbar
                    lang={this.state.language}
                    onOverlayToggle={this.handleOverlayToggle}
                    onLanguageToggle={this.handleToggleLanguage}/>
                <Route {...this.props}/>
                <MobileLinks 
                    lang={this.state.language}
                    open={this.state.visibleLinks} 
                    onClick={this.handleOverlayToggle}
                    onLanguageToggle={this.handleToggleLanguage}/>
            </div>
        );
    }
}

export default persist(LandingLayout);
