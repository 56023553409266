import React from 'react';

import Typography from '@material-ui/core/Typography';

import './Quote.scss';

const Quote = ({ title, subtitle, negative }) => (
    <hgroup className={`quote ${negative ? "quote--negative" : ""}`}>
        <div className="quote__first-line"><Typography variant="h2"><strong>{title[0]}</strong>{title.substring(1)}</Typography></div>
        <div className="quote__second-line"><Typography variant="h2">{subtitle}</Typography></div>
    </hgroup>
)

export default Quote;
