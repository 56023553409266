import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import './Bike.scss';

const Title = ({level, children}) => <Typography variant={'h' + level}>{children}</Typography>
const Href = ({href, children}) => <a href={href} target='_blank'>{children}</a>

const BikeComponent = ({ strings, pictures, document }) => {
    return (
        <div className="Bike">
            <Grid container spacing={40} alignItems='center'>
                <Grid item xs={12} md={6}>
                    <img className='Bike__image Bike__image--tilt-right' src={pictures.bike1} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="Bike__textbox Bike__textbox--right">
                        <Markdown
                            source={strings.paragraph_one.title}
                            renderers={{
                                heading: Title,
                            }}
                            />
                        <Markdown
                            source={strings.paragraph_one.body}
                            renderers={{
                                paragraph: Typography,
                            }}
                            />
                        <div>
                            <Button
                                variant='outlined'
                                component='a'
                                href={document}
                                target='_blank'>
                                {strings.paragraph_one.cta}
                            </Button>
                            {strings.paragraph_one.cta_offer !== '' && <Button
                                style={{marginTop: 8}}
                                variant='outlined'
                                component='a'
                                href={document}
                                target='_blank'>
                                {strings.paragraph_one.cta_offer}
                            </Button>}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} className="Bike__spacer"/>
            </Grid>
            <Grid container spacing={40} alignItems='center' className='reverse-on-sm'>
                <Grid item xs={12} md={6}>
                    <div className="Bike__textbox Bike__textbox--left">
                        <Markdown
                            source={strings.paragraph_two.body}
                            renderers={{
                                paragraph: Typography,
                                link: Href,
                            }}
                            />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img className='Bike__image Bike__image--tilt-left' src={pictures.bike2} />
                </Grid>
            </Grid>
        </div>
    )
}

export default BikeComponent;
