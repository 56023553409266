import React from 'react';
import LocalizedStrings from 'react-localization';

import HabitatComponent from './HabitatComponent';

import STRINGS_IT from '../../assets/data/habitat.json';
import STRINGS_EN from '../../assets/data/en/habitat.json';
import towels from '../../assets/img/towels.png';
import roll from '../../assets/img/roll.png';
import brush from '../../assets/img/brush.png';
import paint from '../../assets/img/paint.png';

const strings = new LocalizedStrings({
    en: STRINGS_EN,
    it: STRINGS_IT,
});

class HabitatContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(localStorage.getItem('Function')) strings.setLanguage(JSON.parse(localStorage.getItem('Function')).language);;

        return (
            <HabitatComponent
                strings={strings}
                pictures={{
                    towels: towels,
                    roll: roll,
                    brush: brush,
                    paint: paint
                }}
                />
        )
    }
}

export default HabitatContainer;
