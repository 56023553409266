import React from 'react';
import LocalizedStrings from 'react-localization';

import STRINGS_IT from '../../assets/data/home.json';
import STRINGS_EN from '../../assets/data/en/home.json';

import HomeComponent from './HomeComponent';

const strings = new LocalizedStrings({
    en: STRINGS_EN,
    it: STRINGS_IT,
});

class HomeContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(localStorage.getItem('Function')) strings.setLanguage(JSON.parse(localStorage.getItem('Function')).language);;

        return (
            <HomeComponent
                strings={strings} />
        )
    }
}

export default HomeContainer;
