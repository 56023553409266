import React from 'react';
import BackgroundSlider from '../common/lib_slider/index';
// import BackgroundSlider from 'react-background-slider';

import Typography from '@material-ui/core/Typography';

import image1 from '../../assets/img/contacts/001.jpg';

import Footer from '../common/footer/Footer';

import './Contacts.scss';

const ContactsComponent = () => {

    return (
        <div className="Contacts" style={{backgroundImage:"url(" + image1 + ")"}}>
            <div className="Contacts__footer">
                <div className="wrapper">
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default ContactsComponent;
