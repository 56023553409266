import React from 'react';
import BackgroundSlider from '../common/lib_slider/index';
// import BackgroundSlider from 'react-background-slider';

import { ParallaxBanner } from 'react-scroll-parallax';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './Breakfast.scss';
import image1 from '../../assets/img/breakfast/001.jpg';
import image2 from '../../assets/img/breakfast/002.jpg';
import image3 from '../../assets/img/breakfast/003.jpg';
import image4 from '../../assets/img/breakfast/004.jpg';
import image5 from '../../assets/img/breakfast/005.jpg';
import image6 from '../../assets/img/breakfast/006.jpg';
import image7 from '../../assets/img/breakfast/007.jpg';
import image8 from '../../assets/img/breakfast/008.jpg';
import image9 from '../../assets/img/breakfast/009.jpg';
import image10 from '../../assets/img/breakfast/010.jpg';

import Quote from '../common/quote/Quote';
import TextBox from '../common/textbox/TextBox';


const BreakfastComponent = ({ strings, pictures }) => {
    const mainTextBox = [
        {
            src: pictures.coffee
        },
        {
            body: strings.main[0],
            centered: true
        },
        {
            src: pictures.milk
        },
        {
            body: strings.main[1],
            centered: true
        },
        {
            src: pictures.breakfast
        },
        {
            body: strings.main[2],
            centered: true
        }
    ]

    return (
        <div className="Breakfast">
            <BackgroundSlider
                images={[
                    image1,
                    image2,
                    image3,
                    image4,
                    image5,
                    image6,
                    image7,
                    image8,
                    image9,
                    image10,
                ]}
                duration={2}/>
            <Grid
                container
                alignItems="center"
                style={{height: "640px"}}>
                <Grid item xs={12} md={6}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Quote
                        title={strings.cover.quote[0]}
                        subtitle={strings.cover.quote[1]}
                        negative
                        />
                </Grid>
            </Grid>

            <div className="Breakfast__text">
                <div className="wrapper">
                    <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                            <TextBox lines={mainTextBox} />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default BreakfastComponent;
