import React from 'react';
import LocalizedStrings from 'react-localization';

import STRINGS_IT from '../../assets/data/who.json';
import STRINGS_EN from '../../assets/data/en/who.json';
import pillow from '../../assets/img/pillow.png';
import teapot from '../../assets/img/teapot.png';
import cup from '../../assets/img/cup.png';
import squeezer from '../../assets/img/squeezer.png';
import scissors from '../../assets/img/scissors.png';
import plant from '../../assets/img/plant.png';
import boots from '../../assets/img/boots.png';
import WhoComponent from './WhoComponent';

const strings = new LocalizedStrings({
    en: STRINGS_EN,
    it: STRINGS_IT,
});

class WhoContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(localStorage.getItem('Function')) strings.setLanguage(JSON.parse(localStorage.getItem('Function')).language);;

        return (
            <WhoComponent
                strings={strings}
                pictures={{
                    pillow: pillow,
                    teapot: teapot,
                    cup: cup,
                    squeezer: squeezer,
                    scissors: scissors,
                    plant: plant,
                    boots: boots
                }}/>
        )
    }
}

export default WhoContainer;
