import React from 'react';
import BackgroundSlider from '../common/lib_slider/index';
// import BackgroundSlider from 'react-background-slider';

import { ParallaxBanner } from 'react-scroll-parallax';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './Habitat.scss';
import image1 from '../../assets/img/habitat/001.jpg';
import image2 from '../../assets/img/habitat/002.jpg';
import image3 from '../../assets/img/habitat/003.jpg';
import image4 from '../../assets/img/habitat/004.jpg';
import image5 from '../../assets/img/habitat/005.jpg';
import image6 from '../../assets/img/habitat/006.jpg';
import image7 from '../../assets/img/habitat/007.jpg';

import Quote from '../common/quote/Quote'
import TextBox from '../common/textbox/TextBox'

const HabitatComponent = ({ strings, pictures }) => {
    const firstTextBox = [
        {
            body: strings.first[0],
            centered: true
        },
        {
            src: pictures.towels
        },
        {
            body: strings.first[1],
            centered: true
        },
        {
            src: pictures.roll
        },
        {
            body: strings.first[2],
            centered: true
        }
    ];

    const secondTextBox = [
        {
            body: strings.second[0],
            centered: true
        },
        {
            src: pictures.brush
        },
        {
            body: strings.second[1],
            centered: true
        },
        {
            src: pictures.paint
        },
        {
            body: strings.second[2],
            centered: true
        }
    ];

    return (
        <div className="Habitat">
            <BackgroundSlider
                images={[
                    image1,
                    image2,
                    image3,
                    image4,
                    image5,
                    image6,
                    image7
                ]}
                duration={2}/>
            <Grid
                container
                alignItems="center"
                style={{height: "640px"}}>
                <Grid item xs={12} md={6}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Quote
                        title={strings.cover.quote[0]}
                        subtitle={strings.cover.quote[1]}
                        negative
                        />
                </Grid>
            </Grid>

            <div className="Habitat__text" style={{marginBottom: "448px"}}>
                <div className="wrapper">
                    <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                            <TextBox lines={firstTextBox} />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                </div>
            </div>
            <div className="Habitat__text">
                <div className="wrapper">
                    <Grid container>
                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <TextBox lines={secondTextBox} />
                        </Grid>
                        <Grid item xs={3} />
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default HabitatComponent;
