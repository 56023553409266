import React from 'react';
import LocalizedStrings from 'react-localization';

import BreakfastComponent from './BreakfastComponent';

import STRINGS_IT from '../../assets/data/breakfast.json';
import STRINGS_EN from '../../assets/data/en/breakfast.json';
import coffee from '../../assets/img/coffee.png';
import milk from '../../assets/img/milk.png';
import breakfast from '../../assets/img/breakfast.png';

const strings = new LocalizedStrings({
    en: STRINGS_EN,
    it: STRINGS_IT,
});

class BreakfastContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(localStorage.getItem('Function')) strings.setLanguage(JSON.parse(localStorage.getItem('Function')).language);;

        return (
            <BreakfastComponent
                strings={strings}
                pictures={{
                    coffee: coffee,
                    milk: milk,
                    breakfast: breakfast
                }}/>
        )
    }
}

export default BreakfastContainer;
